import * as React from "react"
import Navbar from "../components/Navbar"
import SuccessPage from "../components/success-pages/SuccessPages"
import Footer from "../components/Footer/index"
import "../components/error-pages/_error-page.scss"

 const  SucessPage = () => {
  let props = {
    image1:  "/images/success-pages/success.svg",
    image1_mobile:  "/images/success-pages/success.svg",
    title1: (
      <>
        <span> thank you!</span>
      </>
    ),
  }

  return (
    <>
      <Navbar />
        <SuccessPage {...props} />
      <Footer />
    </>
  )
}

export default SucessPage