import React, { useContext } from "react"
import "./_success-page.scss"
import { string_translation, createMarkup } from "../../../utils"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import {Link, useStaticQuery, graphql} from "gatsby"

function SucessPages(props) {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  
  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      goBack_ar:translateString(language: AR, string: "CLICK HERE TO GO BACK")
      goBack_en:translateString(language: EN, string: "CLICK HERE TO GO BACK")

      home_ar:translateString(language: AR, string: "HOMEPAGE NAME")
      home_en:translateString(language: EN, string: "HOMEPAGE NAME")

      title1_ar:translateString(language: AR, string: "THANK YOU")
      title1_en:translateString(language: EN, string: "THANK YOU")

      title2_ar:translateString(language: AR, string: "YOUR SUBMISSION HAS BEEN RECIEVED")
      title2_en:translateString(language: EN, string: "YOUR SUBMISSION HAS BEEN RECIEVED")
    }
  }`)

  return (
    <>
      <section className="success-prompt">
        <div className="container">
          <div className="success-wrapper">
            <div className="img-wrapper desktop-show">
              <img src={props.image1} alt={ string_translation(stringTranslations,"title1", locale)} />
            </div>
            <div className="img-wrapper mobile-show">
              <img src={props.image1_mobile} alt={ string_translation(stringTranslations,"title1", locale)} />
            </div>
            <div className="big-text" >
              <span dangerouslySetInnerHTML={createMarkup(
                      string_translation(stringTranslations,"title1", locale)
                    )}>
                    </span>
            </div>
            <br />
            <div className="big-text">{string_translation(stringTranslations,"title2", locale)}</div>
            <div className="back-home">
            {string_translation(stringTranslations,"goBack", locale)}{" "}
              <Link to={"/"+locale} className="click-home">
                {" "}
                {string_translation(stringTranslations,"home", locale)}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default SucessPages
